.icon {
  display: inline-block;
  vertical-align: middle;
  min-width: 0.1rem;
  min-height: 0.1rem;
  background-repeat: no-repeat;
  background-position: center;
}

.icon-cart-red {
  width: 27px;
  height: 24px;
  background-image: url("data:image/svg+xml,%3Csvg width='28' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.885 18.851c-1.543 0-2.794 1.153-2.794 2.575C6.09 22.846 7.34 24 8.885 24s2.795-1.153 2.795-2.575c0-1.421-1.251-2.574-2.795-2.574zm0 4.005c-.857 0-1.552-.64-1.552-1.43 0-.79.695-1.43 1.552-1.43.858 0 1.553.64 1.553 1.43 0 .79-.695 1.43-1.553 1.43zM20.685 18.851c-1.543 0-2.794 1.153-2.794 2.575 0 1.421 1.25 2.574 2.794 2.574s2.795-1.153 2.795-2.575c0-1.421-1.251-2.574-2.795-2.574zm0 4.005c-.857 0-1.552-.64-1.552-1.43 0-.79.695-1.43 1.552-1.43.858 0 1.553.64 1.553 1.43 0 .79-.695 1.43-1.553 1.43zM26.989 3.833a.802.802 0 00-.497-.229L6.277 3.347l-.559-1.573C5.324.722 4.255.012 3.048 0H.968C.623 0 .345.256.345.572c0 .316.278.572.621.572h2.08c.676.014 1.27.413 1.491 1.001l3.944 10.956-.31.658a2.561 2.561 0 00.279 2.489c.517.727 1.386 1.175 2.329 1.201h12.079c.343 0 .621-.256.621-.572 0-.316-.278-.572-.621-.572h-12.08a1.615 1.615 0 01-1.304-.686 1.466 1.466 0 01-.155-1.373l.249-.515 13.072-1.259c1.437-.146 2.618-1.108 2.95-2.403l1.491-5.75a.457.457 0 00-.093-.486zM24.38 9.812c-.201.833-.97 1.448-1.894 1.516L9.57 12.558 6.68 4.49l19.035.258-1.336 5.063z' fill='%23BF2B49'/%3E%3C/svg%3E");
}

.icon-cart-white {
  width: 16px;
  height: 16px;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.104 12.567c-.923 0-1.67.769-1.67 1.717 0 .948.747 1.716 1.67 1.716.922 0 1.67-.768 1.67-1.716s-.748-1.717-1.67-1.717zm0 2.67a.941.941 0 01-.928-.953c0-.527.415-.954.928-.954.512 0 .928.427.928.954a.941.941 0 01-.928.953zM12.157 12.567c-.923 0-1.67.769-1.67 1.717 0 .948.747 1.716 1.67 1.716.922 0 1.67-.768 1.67-1.716s-.748-1.717-1.67-1.717zm0 2.67a.941.941 0 01-.928-.953c0-.527.415-.954.928-.954.512 0 .928.427.928.954a.941.941 0 01-.928.953zM15.924 2.555a.46.46 0 00-.297-.152L3.545 2.23 3.21 1.182A1.712 1.712 0 001.615 0H.37A.376.376 0 000 .381c0 .211.166.382.371.382h1.244c.403.009.759.275.89.667l2.358 7.304-.186.439a1.874 1.874 0 00.167 1.659c.309.484.829.784 1.392.8h7.22c.205 0 .371-.17.371-.38a.376.376 0 00-.371-.382h-7.22a.942.942 0 01-.78-.458 1.074 1.074 0 01-.092-.915l.148-.343 7.814-.84a2.061 2.061 0 001.763-1.601l.891-3.833a.331.331 0 00-.056-.325zm-1.559 3.986c-.12.556-.58.966-1.132 1.01l-7.72.82-1.727-5.377 11.377.172-.798 3.375z' fill='%23FFFFFF'/%3E%3C/svg%3E");
}
