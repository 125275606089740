.Bun {
  position: relative;
  overflow: hidden;
  box-shadow: 0 0.1rem 1.1rem -0.1rem rgba(0,0,0,0.3);
  border-radius: 0.5rem;
}

.Bun-small {
  min-width: 9rem;
  min-height: 6rem;
}

.Bun-big {
  min-width: 16rem;
  min-height: 12rem;
}

.Bun-image {
  position: absolute;
  top: 0; left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.Bun-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1.01);
  background: linear-gradient(135deg, #bbb, #ccc);
}

.Bun-text {
  position: absolute;
  left: 0;
  width: 100%;
  color: #ffffff;
}

.Bun-text a {
  color: inherit;
}

.Bun-content {
  position: relative;
  width: 100%;
  height: 100%;
}

.Bun-text, .Bun-content {
  padding: 0.65rem;
  font-size: 1rem;
}

.Bun-image ~ .Bun-text,
.Bun-image ~ .Bun-content {
  text-shadow: 0 0 0.5rem dimgray, 0 0 0.1rem dimgray;
}

.Bun-small .Bun-text,
.Bun-small .Bun-content {
  padding: 0.3rem 0.5rem;
  font-size: 0.9rem;
}

.Bun-big .Bun-text,
.Bun-big .Bun-content {
  padding: 0.6rem 0.75rem;
  font-size: 1.15rem;
  line-height: 1.35;
  letter-spacing: 0.2px;
}

.Bun-text-top {
  top: 0;
}

.Bun-text-bottom {
  bottom: 0;
}

.Bun-text-right {
  text-align: right;
}

@media (max-width: 480px) {
  .Bun-big .Bun-text, .Bun-big .Bun-content {
    line-height: 1.1;
  }
  .Bun-small {
    min-width: 8rem;
    min-height: 5rem;
  }
}
