.Reviews { }

.Review {
  margin-bottom: 1rem;
  padding: 0.75rem;
}

.ReviewTitle {
  color: dimgray;
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
}

.ReviewRating {
  color: gold;
  text-shadow: 1px 1px 0 goldenrod;
}

.ReviewContent {
  font-size: 0.8rem;
  padding-top: 0.5rem;
}

.ReviewAuthor {
  padding-top: 0.5rem;
  font-size: 0.65rem;
  line-height: 1;
}
