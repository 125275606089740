.Order {

}

.OrderProp-title {
  font-size: 0.8rem;
  opacity: 0.65;
}

.OrderSection-title {
  font-size: 1.2rem;
  padding-bottom: 0.5rem;
}

.OrderTotal-text {
  padding-top: 1rem;
}

.OrderForm {

}

.OrderProduct {
  max-width: 24rem;
}

.OrderProduct + .OrderProduct {
  margin-top: 0.5rem;
}

.OrderProduct-title {
  font-weight: bold;
}

.OrderProduct-variants {
  font-size: 0.8rem;
}

.OrderProduct-options {
  font-size: 0.65rem;
}

.OrderProduct-options span + span::before {
  content: "; ";
}

.OrderProduct-price {
  font-style: italic;
  padding-left: 0.5rem;
}

.OrderTotal {
  font-weight: bold;
  font-style: italic;
}

.OrderErrors {
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: #BF2B49;
  color: #fff;
}

.OrderError::before {
  content: "➜ ";
}

.PromoProduct-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.PromoProduct {
  padding: 0.25rem;
  margin: 0.25rem;
  background-color: #fff;
  border-radius: 0.25rem;
  cursor: pointer;
}

.PromoProduct-image {
  width: 5rem;
  height: 5rem;
  object-fit: cover;
}

.PromoProduct-title {
  font-size: 0.75rem;
  text-align: center;
}

.PromoProduct-variant {
  font-size: 0.6rem;
  opacity: 0.8;
}

.PromoProductSelect-list {
  margin: 0 -0.25rem 0.25rem;
  display: flex;
  flex-wrap: wrap;
  max-width: 24.5rem;
}

.PromoProductSelect-button {
  padding: 0.25rem 0.5rem;
  margin: 0.25rem;
  color: inherit;
  background-color: #fff;
  border: 0.1rem solid #529d64;
  border-radius: 0.35rem;
}
