.ModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1000;
  animation: darken 0.2s ease;
}

.Modal {
  max-width: min(100%, 40rem);
  max-height: 100%;
  position: relative;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 0.5rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(1rem);
  overflow-y: auto;
  animation: fade-down 0.3s ease-out backwards;
}

.ModalHeader {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding-bottom: 0.5rem;
}

.ModalClose {
  flex-shrink: 0;
  margin-left: 0.5rem;
  padding: 0;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1.5rem;
  line-height: 1.4rem;
  text-align: center;
  color: rgba(0, 0, 0, 0.75);
  background-color: #FFFFFF;
  border: 0 none;
  border-radius: 50%;
  transform: scale(1);
  transition: transform 0.2s ease;
  box-shadow: 0 0.1rem 0.5rem -0.1rem rgba(0, 0, 0, 0.3);
}

.ModalClose:hover {
  transform: scale(1.125);
}

.ModalTitle {
  font-size: 1.25rem;
}

.ModalContent {
  
}

@keyframes fade-down {
  from { opacity: 0; transform: translateY(-25%); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes darken {
  from { background-color: rgba(0, 0, 0, 0); }
  to { background-color: rgba(0, 0, 0, 0.2); }
}
