.Notifications {
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column-reverse;
  padding: 1rem;
}

.Notification {
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(1rem);
  animation: popin 0.5s ease-out backwards;
}

.Notification + .Notification {
  margin-bottom: 0.5rem;
}

@keyframes popin {
  from { opacity: 0; transform: translate(100%) scale(0); }
  to { opacity: 1; transform: translate(0) scale(1); }
}
