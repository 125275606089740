.Cart {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: auto;
  height: 100%;
  min-width: 15rem;
  max-width: min(100vw, 20rem);
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, visibility 0.3s linear;
  backdrop-filter: blur(1rem);
  z-index: 1000;
}

.Cart.opened {
  transition-timing-function: ease-out;
  visibility: visible;
}

.Cart.closed {
  transform: translateX(100%);
  transition-timing-function: ease-in;
  visibility: hidden;
}

.Cart-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0.1rem 0.5rem -0.1rem rgba(0, 0, 0, 0.3);
}

.Cart-title {
  padding: 1rem 1rem 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5rem;
}

.Cart-list {
  padding: 0.5rem 1rem;
  overflow-y: auto;
}

.Cart-summary {
  padding: 0.5rem 1rem 1rem;
}

.CartItem {
  position: relative;
  font-size: 0.8rem;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 0px 0.5rem rgba(0, 0, 0, 0.3);
  border-radius: 0.25rem;
  overflow: hidden;
}

.CartItem + .CartItem {
  margin-top: 0.75rem;
}

.CartItem-remove {
  position: absolute;
  top: 0rem;
  right: 0rem;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1rem;
  background-color: #fff;
  border-radius: 0 0.25rem 0 0.25rem;
}

.CartItem-remove:hover {
  color: #fff;
  background-color: #bf2b49;
}

.CartItem-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.CartItem-info {
  position: relative;
  padding: 0.5rem 2rem 0.5rem 0.5rem;
  background-image: linear-gradient(to right, rgba(255,255,255,0.9), rgba(255,255,255,0.5));
}

.CartItem-title {
  font-size: 1rem;
}

.CartItem-variants {

}

.CartItem-options {
  font-size: 0.65rem;
}

.CartItem-options span + span::before {
  content: " • ";
}

.CartItem-quantity {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
}

.CartItem-price {
  
}

.Cart-order {
  padding: 0.75rem 0 0;
}

.Cart-OrderButton {
  padding: 0 1rem;
  width: 100%;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 2.5rem;
  text-align: center;
  color: #fff;
  background-color: #529d64;
  border-radius: 0.5rem;
}
