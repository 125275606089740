.Promt {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 1rem;
}

.PromtDialog {
  max-width: min(15rem, calc(100vw - 2rem));
  animation: popup 1s ease-out 3s backwards;
}

.PromtText {
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
}

.PromtAgree {
  padding: 0 0.75rem;
  font-size: 0.9rem;
  line-height: 2rem;
  text-align: center;
  color: #fff;
  background-color: #529d64;
  border-radius: 0.5rem;
}

@keyframes popup {
  from { transform: translate(0, calc(100% + 1rem)); }
  to { transform: translate(0, 0); }
}
