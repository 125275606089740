.Fetching {
  display: flex;
  justify-content: center;
  position: relative;
  padding: 1rem;
  color: rgba(0, 0, 0, 0.67);
  font-size: 1rem;
  font-variant: small-caps;
  letter-spacing: 0.05rem;
  animation: blink 2s ease-in-out infinite;
}

.icon-pizza {
  width: 3rem;
  height: 3rem;
  background-size: contain;
  background-image: url(pizza.png);
  animation: spin 3s linear infinite;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  33% {
    opacity: 1;
  }
  67% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
