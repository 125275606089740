.Home section {
  position: relative;
}

.HomeSectionTitle {
  position: absolute;
  top: 1rem;
  width: 100%;
  color:rgba(0, 0, 0, 0.65);
  font-size: 0.8rem;
  line-height: 0.8rem;
  text-transform: lowercase;
  text-align: center;
}

.About-section {
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
  background-color: #FFF;
  text-shadow: rgba(128, 128, 128, 0.35) 0.05rem 0.05rem 0;
}

.Promo-section {
  /* padding-top: 1.25rem; */
}

.Popular-section {
  padding-top: 1.25rem;
}

.ProductsMenu-section {
  color: #ffffff;
  background-color: #555555;
  background-size: cover;
  background-position: center;
}

.ProductsMenu {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 480px;
  padding: 2rem;
  text-align: center;
  color: #454545;
  background: rgba(255, 255, 255, 0.75);
  box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(1.5rem);
  border-radius: 2rem;
}

.ProductsMenu-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: inherit;
  font-size: 0.85rem;
  font-weight: bold;
  line-height: 1rem;
}

.ProductsMenu-item-title {
  position: relative;
  transform-origin: center top;
  transition: transform 0.3s ease;
}

.ProductsMenu-item-image-shadow {
  display: block;
  position: absolute;
  top: 0;
  height: 80px;
  width: 80px;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  filter: blur(0.95rem);
  transform: scale(1.05);
}

.ProductsMenu-item-image {
  position: relative;
  display: block;
  width: 80px;
  height: 80px;
  background-color: #999;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 0.5rem;
  transition: transform 0.3s ease;
}

.ProductsMenu-item:hover .ProductsMenu-item-image,
.ProductsMenu-item:hover .ProductsMenu-item-title {
  transform: scale(1.15);
}

.Social-section {

}

@media (max-width: 480px) {
  .ProductsMenu {
    padding: 2rem 1.5rem 1.5rem;
  }
}
