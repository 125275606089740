.Records {

}

.RecordsList {
  
}

.Record {
  padding: 1rem;
}

.RecordTitle {
  padding-bottom: 0.5rem;
}

.RecordSmall {
  padding: 0.5rem 0.75rem;
}

.RecordSmallTitle {
  font-size: 1.2rem;
  padding-bottom: 0.5rem;
}

.RecordSmallContent {
  font-size: 0.8rem;
}
