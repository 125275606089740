.Header {
  position: relative;
  position: sticky;
  top: 0;
  color: #ffffff;
  background-color: #BF2B49;
  box-shadow:  0 0 0.75rem rgba(0,0,0,0.45);
  z-index: 1000;
}

.Header a {
  font-weight: 700;
  text-decoration: none;
}

.Header-Body {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.Header a {
  color: inherit;
}

.Header-Logo {
  margin: -0.15rem 1.5rem -0.15rem 0;
  color: #000000;
  background-color: #ffffff;
  width: 88px;
  height: 88px;
  border-radius: 50%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.Header-Logo:hover,
.Header-Logo:focus {
  transform: scale(1.05);
}

.Header-Logo img {
  width: 80px;
  height: 80px;
  vertical-align: middle;
}

.Header-Content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Header-Info {
  font-size: 0.75rem;
  font-style: italic;
  margin: 0 -0.15rem;
}

.Header-Info > div {
  padding: 0 0.15rem;
}

/* .Header-Info p {
  margin: 0;
} */

.Header-Menu {
  font-size: 1rem;
  text-transform: uppercase;
  margin: -0.25rem -0.5rem;
}

.Header-Menu li {
  margin: 0.25rem 0.25em 0;
}

.Header-Menu a {
  display: block;
  padding: 0.25rem 0.5rem;
  border: 2px solid transparent;
  border-radius: 0.25rem;
  transition: color 0.1s ease, background-color 0.2s ease, border-color 0.2s ease;
}

.Header-Menu a:hover,
.Header-Menu a:focus {
  border-color: #fff;
}

.Header-Menu .active {
  color: #222;
  background-color: #fff;
  box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.3);
}

.MiniCart {
  margin-left: 1.5rem;
}

.MiniCart-Button {
  padding: 0.4rem 0.5rem;
  color: #000000;
  background-color: #ffffff;
  border-radius: 0.25rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.MiniCart-Button-Quantity {
  display: inline-block;
  vertical-align: middle;
  width: 1rem;
  height: 1rem;
  font-style: italic;
  font-weight: bold;
  font-size: 14px;
  line-height: 1rem;
  text-align: center;
  color: #ffffff;
  background-color: #BF2B49;
  border-radius: 50%;
}

.MiniCart-Button-Total {
  display: inline-block;
  vertical-align: middle;
  font-style: italic;
  font-weight: bold;
  font-size: 18px;
  text-align: right;
  color: #529D64;
}

.MenuButton {
  position: relative;
  display: inline-block;
  padding: 0;
  width: 2.25rem;
  height: 2.25rem;
  background: transparent;
}

.MenuButton span {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 1.75rem;
  height: 0.3rem;
  background-color: #fff;
  border-radius: 0.15rem;
  transform: translate(-50%, -50%) rotate(0);
  transform-origin: center center;
  transition: transform 0.3s ease-out, opacity 0.1s ease;
}
.MenuButton span:nth-child(1) {
  transform: translate(-50%, calc(-50% - 0.6rem)) rotate(0);
}
.MenuButton span:nth-child(2) {
  opacity: 1;
}
.MenuButton span:nth-child(3) {
  transform: translate(-50%, calc(0.6rem - 50%)) rotate(0);
}

.MenuButton.opened span:nth-child(1) {
  transform: translate(-50%, -50%) rotate(45deg);
}
.MenuButton.opened span:nth-child(2) {
  opacity: 0;
  transform: translate(-150%, -50%) rotate(0) scale(0.1);
}
.MenuButton.opened span:nth-child(3) {
  transform:  translate(-50%, -50%) rotate(-45deg);
}

@media (min-width: 961px) {
  .MiniCart {
    margin-left: 1rem;
  }
}

@media (min-width: 481px) and (max-width: 960px) {
  .Header-Logo {
    width: 4rem;
    height: 4rem;
    margin-right: 0.75rem;
  }
  .Header-Logo img {
    width: 3.5rem;
    height: 3.5rem;
  }
  .Header-Info {
    justify-content: space-between;
  }
  .Header-Menu {
    font-size: 0.8rem;
    margin: 0 -0.15rem 0;
  }
  .Header-Menu li {
    margin: 0.15rem 0.15rem 0;
  }
  .MiniCart {
    margin-left: 0.5rem;
  }
}

@media (max-width: 480px) {
  .Header-Logo {
    width: 3rem;
    height: 3rem;
    margin-right: 0.5rem;
  }
  .Header-Logo img {
    width: 2.65rem;
    height: 2.65rem;
  }
  .Header-Content {
    order: 1;
    padding-top: 0.75rem;
  }
  .Header-Body {
    flex-wrap: wrap;
    padding-top: 0.35rem;
    padding-bottom: 0.35rem;
  }
  .Header-Logo {
    margin-top: 0;
    margin-bottom: -1rem;
    margin-left: -0.25rem;
  }
  .Header-Menu {
    font-size: 0.8rem;
    margin: -0.15rem -0.15rem 0;
  }
  .Header-Menu li {
    margin: 0.15rem 0.15rem 0;
  }
  .Header-Menu a {
    padding: 0.25rem 0.25rem;
  }
  .MiniCart {
    margin-left: 0;
  }
}
