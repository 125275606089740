.ProductCategories-wrap {
  background-color: #F2F2F2;
}

.ProductCategories {
  padding: 0.25rem;
}

.ProductCategory {
  display: inline-block;
  margin: 0.5rem;
  padding: 0.2rem 0.75rem;
  font-weight: bold;
  font-size: 18px;
  color: #222222;
  background: #FFFFFF;
  box-shadow: 0 0.1rem 0.5rem -0.1rem rgba(0, 0, 0, 0.3);
  border-radius: 0.75rem;
}

.ProductCategory.current {
  color: #ffffff;
  background-color: #529D64;
}

.Products {
  
}

.ProductsList-wrap {
  padding: 1rem 2.5rem;
}

.ProductsList {
  display: flex;
  flex-wrap: wrap;
}

.Product {
  display: flex;
  flex-direction: column;
  margin: 0.75rem;
  width: calc(25% - 1.5rem);
  background: #ffffff;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.3);
  border-radius: 0.5rem;
  overflow: hidden;
}

.ProductTop {
  position: relative;
  overflow: hidden;
}

.ProductImage {
  position: relative;
  width: 100%;
  height: 150px;
  background-color: gray;
}

.ProductImage img {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  transform: translate(-50%, -50%) scale(1.015625);
  transition: transform 0.3s ease-out;
}

.Product:hover img {
  transition-duration: 1s;
  transform: translate(-50%, -50%) scale(1.125);
}

.ProductDescription {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 0.8rem;
  line-height: 1.2rem;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.65);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s;
}

.ProductDescription.shown {
  opacity: 1;
  visibility: visible;
}

.ProductDescriptionToggle {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 1.25rem;
  height: 1.25rem;
  text-align: center;
  font-family: serif;
  font-size: 1.1rem;
  font-weight: bold;
  line-height: 0.8rem;
  color: #BF2B49;
  background-color: rgba(255, 255, 255, 1);
  border: 0.1rem solid rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  box-shadow: 0 0 0.15rem 0.1rem rgba(0, 0, 0, 0.25);
}

.ProductDescriptionToggle::before {
  content: 'i';
}

.ProductDescriptionToggle.active {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
}

.ProductBottom {
  position: relative;
  padding: 0.5rem 0.75rem 1.5rem;
  background-color: #ffffff;
  flex-grow: 1;
}

.ProductTitle {
  color: #454545;
  font-size: 18px;
  line-height: 21px;
}

.ProductPrice {
  position: absolute;
  bottom: 0.5rem;
  left: 0.75rem;
  font-style: italic;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
}

.ProductVariants {
  padding: 0.25rem 0;
  font-size: 0.7rem;
}

.ProductVariant {
  padding: 0.1rem 0;
}

.ProductVariantValue {
  padding: 0.05rem 0.2rem;
  background-color: #ffffff;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.ProductVariantValue.current {
  color: #529D64;
  border-color: #529d6477;
}

.ProductVariantsTitle {
  opacity: 0.5;
}

.ProductVariantSelectOpen {
  padding: 0;
  color: #529D64;
  background-color: #ffffff;
  border: 1px solid #529d6477;
  border-radius: 0.25rem;
}

.ProductVariantSelectOpen span {
  display: inline-block;
  padding: 0.05rem 0.2rem;
}

.ProductVariantSelectOpen span:first-child {
  border-right: 1px solid #529d6477;
}

.ProductVariantSelectOpen span:last-child {
  color: rgba(0,0,0,0.5);
  font-size: 0.5rem;
  text-transform: uppercase;
}

.ProductOptions {
  padding-bottom: 0.25rem;
  font-size: 0.65rem;
}

.ProductOption {
  padding: 0.25rem 0;
  font-size: 0.7rem;
}

.ProductOption + .ProductOption::before {
  content: " • ";
}

.ProductOptionsSelectOpen {
  padding: 0.05rem 0.2rem;
  color: #529D64;
  background-color: #ffffff;
  border: 1px solid #529d6477;
  border-radius: 0.25rem;
}

.ProductOptionsSelect-wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.ProductOptionsSelect {
  max-width: min(100%, 40rem);
  max-height: 100%;
  position: relative;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 0.5rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(1rem);
  overflow-y: auto;
}

.ProductOptionsSelectTitle {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
}

.ProductOptionsSelectTitle span {
  font-size: 1.25rem;
}

.ProductOptionsSelectClose {
  padding: 0;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1.5rem;
  line-height: 1.4rem;
  text-align: center;
  color: rgba(0, 0, 0, 0.75);
  background-color: #FFFFFF;
  border: 0 none;
  border-radius: 50%;
  box-shadow: 0 0.1rem 0.5rem -0.1rem rgba(0, 0, 0, 0.3);
}

.ProductOptionsList {
  padding-top: 0.5rem;
  font-size: 0.8rem;
}

.ProductOptionsListItem {
  padding: 0;
  margin: 0 0.25rem 0.25rem 0;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 0.25rem;
}

.ProductOptionsListItem span {
  padding: 0.05rem 0.2rem;
}

.ProductOptionsListItem span:last-child {
  font-style: italic;
  font-weight: 700;
  border-left: 1px solid rgba(0, 0, 0, 0.25);
}

.ProductOptionsSelectedList {
  padding-top: 0.25rem;
  display: flex;
  flex-wrap: wrap;
}

.ProductOptionsSelected {
  padding-top: 0.5rem;
}

.ProductOptionsSelectedTitle {
  font-size: 1rem;
}

.ProductOptionsSelectedEmpty {
  font-size: 0.8rem;
  opacity: 0.5;
}

.ProductOptionsSelectedItem {
  display: flex;
  font-size: 0.8rem;
  margin: 0 0.25rem 0.25rem 0;
}

.ProductOptionsItemTitle {
  padding: 0.05rem 0.2rem;
  text-align: center;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-width: 1px 0 1px 0;
}

.ProductOptionsDecrease,
.ProductOptionsIncrease {
  width: 1rem;
  padding: 0.05rem 0.2rem;
  text-align: center;
  background-color: #ffffff;
  border: 1px solid transparent;
}

.ProductOptionsDecrease {
  border-radius: 0.25rem 0 0 0.25rem;
  color: #BF2B49;
  border-color: #BF2B4977;
}

.ProductOptionsIncrease {
  color: #529D64;
  border-color: #529d6477;
  border-radius: 0 0.25rem 0.25rem 0;
}

.ProductVariantsSelect-wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.ProductVariantsSelect {
  max-width: min(100%, 40rem);
  max-height: 100%;
  position: relative;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 0.5rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(1rem);
  overflow-y: auto;
}

.ProductVariantsList {
  padding-top: 0.5rem;
}

.ProductVariantsListItem + .ProductVariantsListItem {
  padding-top: 1rem;
}

.ProductVariantsListItemTitle {
  font-weight: bold;
}

.ProductVariantsListSubitem {
  padding: 0.05rem 0.2rem;
  margin: 0 0.25rem 0.25rem 0;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 0.25rem;
}

.ProductVariantsListSubitem.current {
  color: #529D64;
  border-color: #529d6477;
}

.ProductButtons {
  display: flex;
  align-items: flex-end;
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
}

.ProductQuantity {
  padding: 0 0.5rem;
}

.ProductQuantitySub, .ProductQuantityAdd {
  width: 1rem;
  height: 1rem;
  padding: 0;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  border-color: currentColor;
  background-color: #ffffff;
  line-height: 0.9rem;
}

.ProductQuantitySub {
  color: #BF2B49;
}

.ProductQuantityAdd {
  color: #529D64;
}

.ProductQuantityNumber {
  display: inline-block;
  width: 1.2rem;
  height: 1rem;
  text-align: center;
  font-size: 0.8rem;
  font-weight: bold;
}

.ProductAddToCart {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  line-height: 1.5rem;
  color: #ffffff;
  background-color: #529D64;
  border-radius: 50%;
}

.Price {
  font-weight: bold;
  font-style: italic;
}

@media (min-width: 1024px) {

}

@media (max-width: calc(1200px - 1.5rem - 1px)) {
  .ProductsList-wrap {
    padding: 1rem 0;
  }
}

@media (max-width: 960px) {
  .Product {
    margin: 0.5rem;
    width: calc(50% - 1rem);
  }
  .ProductsList-wrap {
    padding: 1rem 0;
  }
}

@media (max-width: 480px) {
  .Product {
    margin: 0.5rem;
    width: 100%;
  }
  .ProductCategories {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .ProductCategory {
    margin: 0.25rem;
  }
  .ProductsList-wrap {
    padding: 1rem 0;
  }
}
